import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableTimeWindows } from 'shared-frontend/src/models/return-method';
import { getEnabledFeatures, StatusViewModel } from 'shared-frontend';
import { PreferredCommunicationMethod } from '../../../models/CustomerReturnRequest';
import {
  CustomerReturn, RescheduleSuccess,
} from '../../../models';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { apiSlice } from '../api/apiSlice';
import { CustomerReturnView, GetCustomerReturnResponse } from '../../../models/GetCustomerReturnResponse';
import { mapGetReturnResponseToCustomerReturn } from './utils';
import {
  InitialCreateModePayload,
  setInitialCreateMode,
  ArticlesWithIncorrectUnit,
  articlesWithIncorrectUnit,
  timeWindowSet,
} from '../standAloneActions/extraReducersActions';
import { CancelReturnSuccessResponse } from '../api/cancelReturn/cancelReturn';
import { closePrompt } from '../prompt/promptSlice';
import { MarketConfig } from '../api/marketConfig/marketConfig';
import { sortTimeWindows } from '../../../utils/TimeWindows/timeWindowsUtils';

export type InitialViewModePayload = {
  accessToken: string;
  getReturnIdentifierParam: string | null;
  isAuthenticated: boolean;
  userId: string | null;
};

export type InitialBaseModePayload = {
  accessToken: string;
  isAuthenticated: boolean;
};

export interface AppStateInterface {
  accessToken: string | null;
  articlesWithIncorrectUnit: ArticlesWithIncorrectUnit;
  customerReturn: CustomerReturn | CustomerReturnView | null;
  failedPickupReason: 'FAILED_TSP' | 'FAILED_CUSTOMER' | null;
  getReturnIdentifierParam: string | null;
  isAuthenticated: boolean;
  preferredCommunicationMethod: PreferredCommunicationMethod;
  rescheduleAvailableTimeWindows: {
    availableTimeWindows: AvailableTimeWindows,
    proposedTimeWindowId: string,
    returnOptionsId: string
  } | null
  returnId: string | null; // id
  returnReference: string | null; // **-**
  selfServiceViewURL: string | null;
  showSaveCustomerReturnButton: boolean;
  showStockCorrection: boolean;
  statusViewModel: StatusViewModel | null;
  userId: string | null;
  features: string[]
}

export const initialState: AppStateInterface = {
  accessToken: 'initial',
  articlesWithIncorrectUnit: null,
  customerReturn: null,
  failedPickupReason: null,
  getReturnIdentifierParam: null,
  isAuthenticated: false,
  preferredCommunicationMethod: 'EMAIL',
  rescheduleAvailableTimeWindows: null,
  returnId: null,
  returnReference: null,
  selfServiceViewURL: null,
  showSaveCustomerReturnButton: false,
  showStockCorrection: false,
  statusViewModel: null,
  userId: null,
  features: [],
};

export function isViewReturn(
  customerReturn: CustomerReturn | CustomerReturnView | null,
): customerReturn is CustomerReturnView {
  return Boolean((customerReturn as any)?.returnAgreement);
}

const FEATURE = {
  showPaidByTsp: 'showPaidByTsp',
} as const;

export type AvailableFeatures = keyof typeof FEATURE;

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setCustomerReturn: (
      state: AppStateInterface,
      action: PayloadAction<CustomerReturn | CustomerReturnView>,
    ) => {
      state.customerReturn = action.payload;
    },
    setReturnReference: (state, action) => {
      state.returnReference = action.payload;
    },
    setPreferredCommunicationMethod: (
      state: AppStateInterface,
      action: PayloadAction<PreferredCommunicationMethod>,
    ) => {
      state.preferredCommunicationMethod = action.payload;
    },
    setShowStockCorrection: (state: AppStateInterface, action: PayloadAction<boolean>) => {
      state.showStockCorrection = action.payload;
    },
    setFailedPickupReason: (state: AppStateInterface, action: PayloadAction<'FAILED_TSP' | 'FAILED_CUSTOMER' | null>) => {
      state.failedPickupReason = action.payload;
    },
    setInitialViewMode: (
      state: AppStateInterface,
      action: PayloadAction<InitialViewModePayload>,
    ) => {
      state.accessToken = action.payload.accessToken;
      state.getReturnIdentifierParam = action.payload.getReturnIdentifierParam;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.userId = action.payload.userId;
    },
    setBaseMode: (
      state: AppStateInterface,
      action: PayloadAction<InitialBaseModePayload>,
    ) => {
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialCreateMode, (
        state: AppStateInterface,
        action: PayloadAction<InitialCreateModePayload>,
      ) => {
        state.accessToken = action.payload.accessToken;
        state.customerReturn = action.payload.customerReturn;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.userId = action.payload.userId;

        const { customerReturn } = action.payload;
        if (!customerReturn) return;
        const { customer: { deliveryInfo: { email, mobile, phone } } } = customerReturn;
        if (email) {
          state.preferredCommunicationMethod = 'EMAIL';
        } else if (mobile || phone) {
          state.preferredCommunicationMethod = 'SMS';
        }
      })
      .addCase(timeWindowSet, (state, action) => {
        state.showSaveCustomerReturnButton = Boolean(action.payload.timeWindowHasChangedInViewMode);
      })
      .addCase(closePrompt, (state, action) => {
        if (action.payload === 'retry-reschedule') {
          state.showSaveCustomerReturnButton = false;
        }
      })
      .addCase(articlesWithIncorrectUnit, (
        state: AppStateInterface,
        action: PayloadAction<ArticlesWithIncorrectUnit>,
      ) => {
        state.articlesWithIncorrectUnit = action.payload;
      })
      .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (
        state: AppStateInterface,
        action: PayloadAction<RescheduleSuccess>,
      ) => {
        if (!isViewReturn(state.customerReturn)) { return; }
        state.showSaveCustomerReturnButton = false;
        state.statusViewModel = action.payload.statusViewModel;
        state.customerReturn.actions = action.payload.actions;
      })
      .addMatcher(apiSlice.endpoints.submitReturn.matchFulfilled, (state, action) => {
        state.returnReference = action.payload.returnReference;
        state.statusViewModel = action.payload.statusViewModel;
      })
      .addMatcher(apiSlice.endpoints.getCustomerReturn.matchFulfilled, (
        state: AppStateInterface,
        action: PayloadAction<GetCustomerReturnResponse>,
      ) => {
        if (!state.accessToken) return;
        state.returnReference = action.payload.returnReference;
        state.returnId = action.payload.returnId;
        state.statusViewModel = action.payload.statusViewModel;
        state.preferredCommunicationMethod = action.payload.customer.preferredCommunicationMethod;
        state.customerReturn = mapGetReturnResponseToCustomerReturn(action.payload);
        state.selfServiceViewURL = action.payload.selfServiceViewURL ?? null;
      })
      .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (
        state: AppStateInterface,
        action: PayloadAction<CancelReturnSuccessResponse>,
      ) => {
        if (!isViewReturn(state.customerReturn)) { return; }
        const updatedVieWModel = action.payload.statusViewModel;
        const updatedActions = action.payload.actions;
        state.showSaveCustomerReturnButton = false;
        state.statusViewModel = updatedVieWModel;
        state.customerReturn.actions = updatedActions;
      })
      .addMatcher(apiSlice.endpoints.marketConfig.matchFulfilled, (
        state: AppStateInterface,
        action: PayloadAction<MarketConfig>,
      ) => {
        state.features = getEnabledFeatures(action.payload.features);
      })
      .addMatcher(apiSlice.endpoints.getAvailableTimeWindows.matchFulfilled, (
        state: AppStateInterface,
        action: PayloadAction<any>,
      ) => {
        if (!isViewReturn(state.customerReturn)) { return; }
        const { returnOption } = state.customerReturn.returnAgreement.returnSettlement;
        const {
          timeWindow: { id, toDateTime, fromDateTime },
          metadata: { timeWindow: { timezone, resourcePoolId, tspData } },
        } = returnOption;
        const currentlySelectedTimeWindow = {
          [id]: {
            toDateTime,
            fromDateTime,
            resourcePoolId,
            timezone,
            tspData,
          },
        };
        const { availableTimeWindows: apiTimeWindows, returnOptionsId } = action.payload;
        state.rescheduleAvailableTimeWindows = {
          returnOptionsId,
          proposedTimeWindowId: id,
          availableTimeWindows: sortTimeWindows({
            ...apiTimeWindows,
            ...currentlySelectedTimeWindow,
          }),
        };
      });
  },
});

export const {
  setCustomerReturn,
  setReturnReference,
  setPreferredCommunicationMethod,
  setFailedPickupReason,
  setInitialViewMode,
  setBaseMode,
} = appStateSlice.actions;

export const {
  setShowStockCorrection,
} = appStateSlice.actions;

export const useGetCustomerReturn = () => useLamnaSelector(
  (state) => state.appState.customerReturn,
);
export const useGetIsAuthenticated = () => useLamnaSelector(
  (state) => state.appState.isAuthenticated,
);
export const useGetAccessToken = () => useLamnaSelector(
  (state) => state.appState.accessToken,
);
export const useGetReturnReference = () => useLamnaSelector(
  (state) => state.appState.returnReference,
);
export const useGetPreferredCommunicationMethod = () => useLamnaSelector(
  (state) => state.appState.preferredCommunicationMethod,
);
export const useGetReturnIdentifierParam = () => useLamnaSelector(
  (state) => state.appState.getReturnIdentifierParam,
);
export const useGetReturnId = () => useLamnaSelector(
  (state) => state.appState.returnId,
);
export const useGetStatusViewModel = () => useLamnaSelector(
  (state) => state.appState.statusViewModel,
);
export const useGetShowSaveCustomerReturnButton = () => useLamnaSelector(
  (state) => {
    const {
      showSaveCustomerReturnButton,
      rescheduleAvailableTimeWindows,
    } = state.appState;
    const originalTime = rescheduleAvailableTimeWindows?.proposedTimeWindowId;
    const rescheduleTime = state.selectedReturnMethod
      ?.selectedReturnMethod?.timeWindow?.timeWindowId;
    const timeWindowIsOriginal = originalTime === rescheduleTime;
    return showSaveCustomerReturnButton && !timeWindowIsOriginal;
  },
);
export const useGetShowStockCorrection = () => useLamnaSelector(
  (state) => state.appState.showStockCorrection,
);
export const useHasStockCorrectionItems = () => {
  const customerReturn = useGetCustomerReturn();
  return customerReturn?.items.some((item) => 'receiving' in item) ?? false;
};
export const useGetRescheduleTimeWindows = () => useLamnaSelector(
  (state) => state.appState.rescheduleAvailableTimeWindows,
);
export const useGetFailedPickupReason = () => useLamnaSelector(
  (state) => state.appState.failedPickupReason,
);
export const useToggleStockCorrection = () => {
  const dispatch = useLamnaDispatch();
  const showStockCorrection = useGetShowStockCorrection();
  return () => (
    dispatch(setShowStockCorrection(!showStockCorrection))
  );
};

export const useSetPreferredCommunicationMethod = () => {
  const dispatch = useLamnaDispatch();
  return (preferredCommunicationMethod: PreferredCommunicationMethod) => (
    dispatch(setPreferredCommunicationMethod(preferredCommunicationMethod))
  );
};
export const useSetFailedPickupReason = () => {
  const dispatch = useLamnaDispatch();
  return (reason: 'FAILED_TSP' | 'FAILED_CUSTOMER' | null) => (
    dispatch(setFailedPickupReason(reason))
  );
};
export const useGetSSUIUrl = () => useLamnaSelector(
  (state) => state.appState.selfServiceViewURL,
);
export const useGetUserId = () => useLamnaSelector(
  (state) => state.appState.userId,
);
export const useGetArticlesWithIncorrectUnit = () => useLamnaSelector(
  (state) => state.appState.articlesWithIncorrectUnit,
);

export const useGetEnabledFeature = (feature: AvailableFeatures) => {
  const activeFeatures = useLamnaSelector(
    (state) => state.appState.features,
  );
  return activeFeatures.includes(feature);
};

export default appStateSlice.reducer;
