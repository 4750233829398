import ReturnMethodCard from '../ReturnMethodCard/ReturnMethodCard';
import RefundMethodCard from '../RefundMethodCard/RefundMethodCard';
import RefundSummary from '../RefundSummaryCard/RefundSummaryCard';
import CustomerDetailsCard from '../CustomerDetailsDataCard/CustomerDetailsDataCard';
import ArticlesReasonCard from '../ArticlesReasonCard/ArticlesReasonCard';
import ControlBar from '../ControlBar/ControlBar';
import { useGetCustomerReturn } from '../../store/features/appStateSlice/appStateSlice';
import StatusbarCard from '../StatusbarCard/StatusbarCard';

function Wrapper({ children }: { children: React.ReactNode }) {
  return <div className="relative">{children}</div>;
}

function App() {
  const customerReturn = useGetCustomerReturn();

  if (!customerReturn) return null;
  return (
    <>
      <ControlBar />
      <main>
        <Wrapper>
          <StatusbarCard />
          <CustomerDetailsCard />
          <ReturnMethodCard />
        </Wrapper>
        <ArticlesReasonCard />
        <Wrapper>
          <RefundSummary />
          <RefundMethodCard />
        </Wrapper>
      </main>
    </>
  );
}

export default App;
