import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SuccessfulScan } from '@resolutions/scanner-contract';
import { ItemAdded, OpenStatus } from '@resolutions/item-picker-contract';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  IN_STORE_POP_NAME, ONLINE_POP_NAME, PopId,
} from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import {
  setActionsSidebarPop,
} from '../standAloneActions/actions';
import {
  filterBlindReturnItemsByItemNo,
  mapFetchedProductToStateItem,
  OTCState,
  PoPSelectedItem,
  removeDots,
  StatePop,
  updateSelectedItemsForPop,
} from './reducerUtils';
import { closeSidebar } from '../sidebar/sidebarSlice';
import {
  removeArticle,
  removeBlindReturnArticle,
  removePoP,
  removeSelectedArticlesPop,
  showOTCArticleSidebar,
  SidebarPageKey,
} from '../standAloneActions/extraReducersActions';
import { apiSlice } from '../api/apiSlice';
import { Product } from '../api/returnAuthorization/products';

export type PopSuccessfulScan = SuccessfulScan<PopId>;

export const OTCInitialState: OTCState = {
  actionsSidebarPopId: null,
  articleInSidebar: null,
  blindReturn: { checked: false, items: [] },
  pops: [],
  pipState: {
    isOpen: false,
    purchaseId: '',
  },
};

export const OTCSlice = createSlice({
  name: 'OTC',
  initialState: OTCInitialState,
  reducers: {
    updateSelectedItemsForPop,
    toggleBlindReturnAllChecked: (state) => {
      state.blindReturn = {
        checked: !state.blindReturn.checked,
        items: state.blindReturn.items.map((item) => ({
          ...item,
          checked: !state.blindReturn.checked,
        })),
      };
    },
    toggleBlindReturnItemChecked: (state, action: PayloadAction<{ itemNo: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemNo !== action.payload.itemNo) return item;

        return {
          ...item,
          checked: !(item as PoPSelectedItem).checked,
        };
      });
    },
    increaseBlindReturnItemQuantity: (state, action: PayloadAction<{ itemNo: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemNo !== action.payload.itemNo) return item;

        return {
          ...item,
          quantity: item.quantity + 1,
        };
      });
    },
    setPipState: (state, action: PayloadAction<OpenStatus>) => {
      const { openStatus, purchaseId } = action.payload;
      state.pipState = {
        isOpen: openStatus === 'open',
        purchaseId,
      };
    },
    togglePopChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string }>,
    ) => {
      const { popId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        return {
          ...pop,
          selectedItems: pop.selectedItems.map((selectedItem) => ({
            ...selectedItem,
            checked: !pop.checked,
          })),
          checked: !pop.checked,
        };
      });
    },
    togglePopSelectedItemsChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string, itemNo: string }>,
    ) => {
      const { popId, itemNo } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        const { selectedItems } = pop;
        const item = selectedItems.find((selectedItem) => selectedItem.itemNo === itemNo);
        if (!item) return pop;
        return {
          ...pop,
          selectedItems: selectedItems.map((selectedItem) => {
            if (selectedItem.itemNo !== itemNo) return selectedItem;
            return {
              ...selectedItem,
              checked: !selectedItem.checked,
            };
          }),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        showOTCArticleSidebar,
        (
          state: OTCState,
          action: PayloadAction<{
            article: PoPSelectedItem,
            sidebarPage: SidebarPageKey,
          }>,
        ) => {
          state.articleInSidebar = action.payload.article;
        },
      )
      .addCase(
        setActionsSidebarPop,
        (state: OTCState, action: PayloadAction<{ popId: string | null }>) => {
          state.actionsSidebarPopId = action.payload.popId;
        },
      )
      .addCase(closeSidebar, (state: OTCState, action: PayloadAction<SidebarPageKey>) => {
        if (action.payload === 'otc-pop-actions') {
          state.actionsSidebarPopId = null;
        }
        if (action.payload === 'otc-article-viewer'
          || action.payload === 'otc-article-actions'
          || action.payload === 'otc-blind-return-article-actions'
        ) {
          state.articleInSidebar = null;
        }
      })
      .addCase(removePoP, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        state.pops = state.pops.filter((pop) => pop.id !== popId);
        state.actionsSidebarPopId = null;
      })
      .addCase(removeArticle, (state: OTCState) => {
        const articleToRemove = state.articleInSidebar;
        state.pops = state.pops.map((pop) => {
          const updatedPop = { ...pop };
          updatedPop.selectedItems = pop.selectedItems.filter(
            (item) => item.itemLineId !== articleToRemove?.itemLineId,
          );
          return updatedPop;
        });
      })
      .addCase(removeBlindReturnArticle, (state) => {
        const articleToRemove = state.articleInSidebar;
        filterBlindReturnItemsByItemNo(state, articleToRemove?.itemNo);
      })
      .addCase(removeSelectedArticlesPop, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        const filterSelectedItems = (pop: StatePop) => {
          if (pop.id === popId) {
            const filteredItems = pop.selectedItems.filter((item) => item.checked === false);
            return {
              ...pop,
              selectedItems: filteredItems,
              checked: false,
            };
          }
          return pop;
        };
        state.pops = state.pops.map(filterSelectedItems);
        state.actionsSidebarPopId = null;
      })
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchPending,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const inStoreRegex = /\d{18}|\d{22}/;

          state.pops = [
            ...state.pops,
            {
              checked: false,
              fetchedResponse: null,
              id: action.meta.arg.originalArgs.purchaseId,
              isFetching: true,
              selectedItems: [],
              type: purchaseId.match(inStoreRegex) ? IN_STORE_POP_NAME : ONLINE_POP_NAME,
            },
          ];
        },
      )
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchRejected,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const updatedPops = state.pops.filter((pop) => pop.id !== purchaseId);
          state.pops = updatedPops;
        },
      )
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchFulfilled,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const fetchedResponse = action.payload;

          const popIndexInState = state.pops.findIndex((pop) => pop.id === purchaseId);
          if (popIndexInState === -1) return;

          const getReturnedQuantity = (blindItemQuantity: number, productQuantity?: number) => {
            if (!productQuantity) return blindItemQuantity;
            return blindItemQuantity > productQuantity ? productQuantity : blindItemQuantity;
          };

          const createSelectedItem = (
            product: Product,
            quantity: number,
            index: number,
          ) => ({
            ...mapFetchedProductToStateItem({
              itemLineId: `${index}`,
              product,
              quantity,
            }),
            checked: state.blindReturn.checked,
          });

          type MappedItems = {
            blindReturnItems: typeof state.blindReturn.items;
            popSelectedItems: PoPSelectedItem[];
          };

          const mappedSelectedItems = state.blindReturn.items.reduce<MappedItems>((
            acc,
            blindItem,
          ) => {
            let remainingBlindItemQuantity = blindItem.quantity;
            for (let i = 0; i < fetchedResponse.products.length; i += 1) {
              const { product } = fetchedResponse.products[i];
              if (removeDots(product.productNumber) === blindItem.itemNo) {
                const quantity = getReturnedQuantity(remainingBlindItemQuantity, product.quantity);
                acc.popSelectedItems.push(
                  createSelectedItem(product, quantity, i),
                );

                remainingBlindItemQuantity -= quantity;
                if (remainingBlindItemQuantity <= 0) break;
              }
            }

            if (remainingBlindItemQuantity > 0) {
              acc.blindReturnItems.push({
                ...blindItem,
                quantity: remainingBlindItemQuantity,
              });
            }
            return acc;
          }, {
            blindReturnItems: [],
            popSelectedItems: [],
          });

          state.blindReturn.items = mappedSelectedItems.blindReturnItems;
          state.pops[popIndexInState] = {
            ...state.pops[popIndexInState],
            fetchedResponse,
            isFetching: false,
            selectedItems: mappedSelectedItems.popSelectedItems,
          };
        },
      )
      .addMatcher(apiSlice.endpoints.getProductInfo.matchPending, (state, action) => {
        const { productNumbers: itemNo } = action.meta.arg.originalArgs;
        state.blindReturn.items = [
          ...state.blindReturn.items,
          {
            isFetching: true,
            itemNo,
            quantity: 1,
          },
        ];
      })
      .addMatcher(apiSlice.endpoints.getProductInfo.matchRejected, (state, action) => {
        const { productNumbers } = action.meta.arg.originalArgs;
        filterBlindReturnItemsByItemNo(state, productNumbers);
      })
      .addMatcher(apiSlice.endpoints.getProductInfo.matchFulfilled, (state, action) => {
        const { productNumbers } = action.meta.arg.originalArgs;
        const [productInResponse] = action.payload;
        const updatedBlindReturnItems = state.blindReturn.items.map((item) => {
          if (item.itemNo !== productNumbers) return item;

          return {
            checked: state.blindReturn.checked,
            ...mapFetchedProductToStateItem({
              itemLineId: `${state.blindReturn.items.length}`,
              product: productInResponse,
            }),
          };
        });
        state.blindReturn.items = updatedBlindReturnItems;
      });
  },
});

export default OTCSlice.reducer;

// convenience selectors
export const useGetPops = () => useLamnaSelector((state) => state.otc.pops);
export const useGetAllSelectedItemsFromPops = () => {
  const pops = useGetPops();
  return pops.flatMap((pop) => pop.selectedItems);
};
export const useGetSelectedItemsForPop = (popId: string | null = '') => {
  const matchingPop = useLamnaSelector((state) => state.otc.pops.find((pop) => pop.id === popId));
  return matchingPop?.selectedItems.filter((item) => item.checked);
};

// convenience dispatchers
export const useTogglePopChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string) => {
    dispatch(OTCSlice.actions.togglePopChecked({ popId }));
  };
};

export const useTogglePopSelectedItemsChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string, itemNo: string) => {
    dispatch(OTCSlice.actions.togglePopSelectedItemsChecked({ popId, itemNo }));
  };
};
export const useUpdateSelectedItemsForPop = () => {
  const dispatch = useLamnaDispatch();
  return (itemAdded: ItemAdded) => {
    dispatch(OTCSlice.actions.updateSelectedItemsForPop(itemAdded));
  };
};
export const useGetPop = (purchaseId: string) => useLamnaSelector(
  (state) => state.otc.pops.find((pop) => pop.id === purchaseId),
);
export const useGetArticleInSidebar = () => useLamnaSelector(
  (state) => state.otc.articleInSidebar,
);
export const useGetPoPForActionsSidebar = () => useLamnaSelector(
  (state) => state.otc.actionsSidebarPopId,
);
export const useGetBlindReturnArticleForActionsSidebar = () => useLamnaSelector(
  (state) => state.otc.articleInSidebar,
);
export const useGetBlindReturn = () => useLamnaSelector((state) => state.otc.blindReturn);

export const useToggleBlindReturnAllChecked = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(OTCSlice.actions.toggleBlindReturnAllChecked());
  };
};
export const useToggleBlindReturnItemChecked = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string) => {
    dispatch(OTCSlice.actions.toggleBlindReturnItemChecked({ itemNo }));
  };
};
export const useIncreaseBlindReturnItemQuantity = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string) => {
    dispatch(OTCSlice.actions.increaseBlindReturnItemQuantity({ itemNo }));
  };
};

export const useSetOpenPip = () => {
  const dispatch = useLamnaDispatch();
  return (pipState: OpenStatus) => {
    dispatch(OTCSlice.actions.setPipState(pipState));
  };
};

export const useGetOpenPip = () => useLamnaSelector(
  (state) => state.otc.pipState,
);
