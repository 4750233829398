import type { PayloadAction } from '@reduxjs/toolkit';
import { ItemAdded, PickedItem } from '@resolutions/item-picker-contract';
import { PopPlugin } from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import { GetProductsResponse, Product } from '../api/returnAuthorization/products';
import { ProductInfo } from '../api/productInfo/getProductInfo';

export type BlindReturnItemFetching = {
  isFetching: boolean;
  itemNo: string;
  quantity: number;
};
export type BlindReturn = {
  checked: boolean;
  items: (BlindReturnItemFetching | PoPSelectedItem)[];
};
export type PoPSelectedItem = PickedItem & { checked: boolean };

export const isPoPSelectedItem = (
  item: BlindReturnItemFetching | PoPSelectedItem,
): item is PoPSelectedItem => !('isFetching' in item);

export type StatePop = {
  checked: boolean;
  fetchedResponse: GetProductsResponse | null;
  id: string;
  isFetching: boolean;
  selectedItems: PoPSelectedItem[];
  type: PopPlugin;
};

export type OTCState = {
  actionsSidebarPopId: string | null;
  articleInSidebar: PoPSelectedItem | null;
  blindReturn: BlindReturn;
  pops: StatePop[];
  pipState: {
    isOpen: boolean,
    purchaseId: string | null,
  }
};

export const updateSelectedItemsForPop = (state: OTCState, action: PayloadAction<ItemAdded>) => {
  const { item, purchaseId } = action.payload;
  state.pops = state.pops.map((pop) => {
    let itemInExistingPopSelectedItems = false;
    if (pop.id !== purchaseId) return pop;

    let updatedPop = {
      ...pop,
      selectedItems: pop.selectedItems.map((popItem) => {
        if (popItem.itemNo !== item.itemNo || popItem.itemLineId !== item.itemLineId) {
          return popItem;
        }

        itemInExistingPopSelectedItems = true;

        const quantity = popItem.quantity + item.quantity;
        const unitPaidPrice = popItem.price.unit.paidPrice;
        const unitPriceExclTax = unitPaidPrice.priceExclTax ?? null;
        const unitPriceInclTax = unitPaidPrice.priceInclTax ?? null;
        return {
          ...popItem,
          deliveryReference: item.deliveryReference,
          dimensions: item.dimensions,
          price: {
            ...popItem.price,
            total: {
              paidPrice: {
                priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
                priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
              },
            },
          },
          quantity,
        };
      }),
    };

    if (!itemInExistingPopSelectedItems) {
      updatedPop = {
        ...pop,
        selectedItems: [
          ...pop.selectedItems,
          { ...item, checked: false }],
      };
    }

    return updatedPop;
  });
};

export const filterBlindReturnItemsByItemNo = (
  state: OTCState,
  filterItemNo: string | undefined,
) => {
  if (!filterItemNo) return;

  const updatedBlindReturnItems = state.blindReturn.items.filter(
    (item) => item.itemNo !== filterItemNo,
  );
  state.blindReturn.items = updatedBlindReturnItems;
};

export const removeDots = (text: string) => text.replace(/\./g, '');

type MapFetchedProductToStateItemProps = {
  deliveryReference?: string;
  itemLineId: string;
  product: Product | ProductInfo;
  quantity?: number;
};
export const mapFetchedProductToStateItem = ({
  deliveryReference = '0',
  itemLineId,
  product,
  quantity = 1,
}: MapFetchedProductToStateItemProps) => {
  const productPrice = 'price' in product ? product.price : null;
  const unitPriceExclTax = productPrice?.priceExclTax ?? null;
  const unitPriceInclTax = productPrice?.priceInclTax ?? null;

  return {
    currencyCode: productPrice?.currencyCode ?? '',
    deliveryReference,
    description: product?.type ?? '', // not received in fetched product
    dimensions: null,
    itemNo: removeDots(product.productNumber),
    itemLineId: 'lineId' in product ? product.lineId : itemLineId,
    itemType: product.itemType ?? 'ART',
    name: product.name ?? 'No name',
    price: {
      total: {
        paidPrice: {
          priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
          priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
        },
      },
      unit: {
        paidPrice: {
          priceExclTax: unitPriceExclTax,
          priceInclTax: unitPriceInclTax,
        },
      },
    },
    productImage: {
      alt: product.media?.alt ?? null,
      url: product.media?.variants.S2 ?? '',
    },
    quantity,
  };
};
