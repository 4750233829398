import { ModalBody } from '@ingka/modal';
import {
  ChooseReturnMethod, IsomReturnOption, TaxKeys, mapMarketConfigToReturnMethods,
} from 'shared-frontend';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import InlineMessage from '@ingka/inline-message';
import { useTranslation } from 'react-i18next';
import {
  useGetSelectedReturnMethodDraft,
  useSelectedReturnMethodDraft,
  useGetSelectedReturnMethod,
  useGetReturnMethodMarketConfig,
} from '../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { EntriesSkeleton } from './EntriesSkeleton';
import { useMarketConfigFetch } from '../../useMarketConfigFetch';
import { isValidatedMarketConfig } from '../../store/features/api/marketConfig/marketConfig';
import { useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';
import {
  useAvailableReturnMethodsRequest,
  useQuestionnaireRequest,
} from '../app/initialFetchUtils';

export function ReturnMethodPage() {
  const { data: marketConfig } = useMarketConfigFetch();
  const { countryCode, languageCode } = useMarketLocale();
  const { t } = useTranslation();
  const { data: returnMethods, isLoading, isError } = useAvailableReturnMethodsRequest();
  const questionnaireRequest = useQuestionnaireRequest();

  const translations = {
    free: t('return-method-sidebar.free'),
    MISSING_SERVICE_PRICE: t('return-method-sidebar.MISSING_SERVICE_PRICE'),
    NO_TIMEWINDOWS: t('return-method-sidebar.NO_TIMEWINDOWS'),
    RPA_NOT_AVAILABLE: t('return-method-sidebar.RPA_NOT_AVAILABLE'),
    PLANNED_UNAVAILABILITY: t('error.service-planned-unavailable-text'),
    returnOptions: t('returnOptions', { returnObjects: true }),
  };

  const selectedReturnMethodDraft = useGetSelectedReturnMethodDraft().selectedReturnMethod;
  const { setReturnMethodDraft } = useSelectedReturnMethodDraft();
  const selectedReturnMethod = useGetSelectedReturnMethod();
  const goToCalendar = useGoToSidebarPage('time-window-calendar');
  const returnMethodMarketConfig = useGetReturnMethodMarketConfig();

  if (!isValidatedMarketConfig(marketConfig)) {
    return null;
  }
  const { purchaseInformation } = marketConfig;

  const priceKey = purchaseInformation?.itemPriceExcludesTax
    ? TaxKeys.priceExclTax
    : TaxKeys.priceInclTax;

  if (isLoading || questionnaireRequest.isLoading) {
    return (
      <ModalBody>
        <EntriesSkeleton />
      </ModalBody>
    );
  }
  if (isError || questionnaireRequest.isError) {
    return (
      <ModalBody>
        <InlineMessage
          subtle
          variant="negative"
          ssrIcon={informationCircle}
          body={t('commonly-reused.errorMessage')}
          className="mt-4"
        />
      </ModalBody>
    );
  }
  if (!returnMethods) return null;

  const methodsWithConfig = returnMethodMarketConfig
    ? mapMarketConfigToReturnMethods({
      returnMethods,
      returnMethodMarketConfig,
      holdUntilPaidConfig: {
        label: t('holdUntilPaid.returnOptionNotification'),
        languageCode,
        countryCode,
      },
    })
    : returnMethods;

  return (
    <ModalBody>
      {returnMethods?.length === 0 ? (
        <InlineMessage
          subtle
          variant="informative"
          ssrIcon={informationCircle}
          body={t('return-method-sidebar.no-available-options')}
          className="mt-4"
        />
      ) : (
        <ChooseReturnMethod<IsomReturnOption>
          selectedReturnMethod={selectedReturnMethodDraft || selectedReturnMethod}
          selectedReturnMethodOnChange={setReturnMethodDraft}
          returnMethods={methodsWithConfig}
          languageCode={languageCode}
          countryCode={countryCode}
          dateCaptionOnClick={goToCalendar}
          priceKey={priceKey}
          translations={translations}
        />
      )}
    </ModalBody>
  );
}
